import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import { OmgServiceContext } from "../../../services/OmgServiceContext";

const fakeData = [
  {
    state_number: 0,
    name: " ",
    short_name: " ",
    areas: [
      {
        area_number: 0,
        name: "Defaut Area",
        phone_number: "(503) 878-73-49",
        address: " ",
        schedule_weekday: "Monday - Saturday: 7 am - 7 pm",
        schedule_weekend: " ",
        special_offer: {
          text: "SERVICE CALL FROM $79 AND 10% OFF FOR FIRST REPAIR",
        },
        cities: [
          {
            city_number: 0,
            name: "Choose your city",
            service_fee: "Service fee - from $79",
            membership_service_fee: "Service fee - $47",
          },
        ],
      },
    ],
  },
  {
    state_number: 1,
    name: "Oregon",
    short_name: "OR",
    areas: [
      {
        area_number: 1,
        name: "Portland",
        phone_number: "(503) 878-73-49",
        address: "Portland | 5150 South Landing Drive, Oregon 97239",
        schedule_weekday: "Monday - Friday: 7 am - 9 pm",
        schedule_weekend: "Saturday - Sunday: 9 am - 6 pm",
        special_offer: {
          text: "SERVICE CALL FROM $79 AND 10% OFF FOR FIRST REPAIR",
        },
        cities: [
          {
            city_number: 0,
            name: "Portland",
            service_fee: "Service fee - $79",
            membership_service_fee: "Service fee - $45",
          },
          {
            city_number: 1,
            name: "Aloha",
            service_fee: "Service fee - $79",
            membership_service_fee: "Service fee - $45",
          },
          {
            city_number: 2,
            name: "Beaverton",
            service_fee: "Service fee - $79",
            membership_service_fee: "Service fee - $45",
          },
          {
            city_number: 3,
            name: "Boring",
            service_fee: "Service fee - $99-120",
            membership_service_fee: "Service fee - $45",
          },
          {
            city_number: 4,
            name: "Canby",
            service_fee: "Service fee - $99-120",
            membership_service_fee: "Service fee - $45",
          },
          {
            city_number: 5,
            name: "Clackamas",
            service_fee: "Service fee - $99-120",
            membership_service_fee: "Service fee - $45",
          },
          {
            city_number: 6,
            name: "Corbett",
            service_fee: "Service fee - $99-120",
            membership_service_fee: "Service fee - $45",
          },
          {
            city_number: 7,
            name: "Cornelius",
            service_fee: "Service fee - $99-120",
            membership_service_fee: "Service fee - $45",
          },
          {
            city_number: 8,
            name: "Damascus",
            service_fee: "Service fee - $79",
            membership_service_fee: "Service fee - $45",
          },
          {
            city_number: 9,
            name: "Fairview",
            service_fee: "Service fee - $79",
            membership_service_fee: "Service fee - $45",
          },
          {
            city_number: 10,
            name: "Gladstone",
            service_fee: "Service fee - $79",
            membership_service_fee: "Service fee - $45",
          },
          {
            city_number: 11,
            name: "Gresham",
            service_fee: "Service fee - $79",
            membership_service_fee: "Service fee $45",
          },
          {
            city_number: 12,
            name: "Happy Valley",
            service_fee: "Service fee - $79",
            membership_service_fee: "Service fee - $45",
          },
          {
            city_number: 13,
            name: "Hillsboro",
            service_fee: "Service fee - $99-120",
            membership_service_fee: "Service fee - $45",
          },
          {
            city_number: 14,
            name: "King City",
            service_fee: "Service fee - $79",
            membership_service_fee: "Service fee - $45",
          },
          {
            city_number: 15,
            name: "Lake Oswego",
            service_fee: "Service fee - $79",
            membership_service_fee: "Service fee - $45",
          },
          {
            city_number: 16,
            name: "Milwaukie",
            service_fee: "Service fee - $79",
            membership_service_fee: "Service fee - $45",
          },
          {
            city_number: 17,
            name: "Oregon city",
            service_fee: "Service fee - $79",
            membership_service_fee: "Service fee - $45",
          },
          {
            city_number: 18,
            name: "Sandy",
            service_fee: "Service fee - $99-120",
            membership_service_fee: "Service fee - $45",
          },
          {
            city_number: 19,
            name: "Sherwood",
            service_fee: "Service fee - $79",
            membership_service_fee: "Service fee - $45",
          },
          {
            city_number: 20,
            name: "Stafford",
            service_fee: "Service fee - $79",
            membership_service_fee: "Service fee - $45",
          },
          {
            city_number: 21,
            name: "Sunnyside",
            service_fee: "Service fee - $79",
            membership_service_fee: "Service fee - $45",
          },
          {
            city_number: 22,
            name: "Tigard",
            service_fee: "Service fee - $79",
            membership_service_fee: "Service fee - $45",
          },
          {
            city_number: 23,
            name: "Troutdale",
            service_fee: "Service fee - $79",
            membership_service_fee: "Service fee - $45",
          },
        ],
      },
    ],
  },
];
export const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  const { getContacts, contactsError } = useContext(OmgServiceContext);
  const contact = getContacts();

  const stateData = contactsError ? fakeData : contact;

  const defaultLocation = {
    city: "Choose your city",
    state: " ",
  };

  const [location, setLocation] = useState(defaultLocation);
  const [stateInfo, setStateInfo] = useState({
    specialOffer: null,
    phoneNumber: null,
    schedule: null,
    serviceFee: null,
    membershipServiceFee: null,
  });

  useEffect(() => {
    if (stateData) {
      const updateStateValues = () => {
        const stateInfoData = stateData.find(
          (state) => state.name === location.state
        );
        const currentArea =
          stateInfoData?.areas.find((area) =>
            area.cities.some((cityItem) => cityItem.name === location.city)
          ) || stateInfoData?.areas[0];
        const currentCity =
          currentArea?.cities.find(
            (cityItem) => cityItem.name === location.city
          ) || currentArea;

        if (stateInfoData) {
          setStateInfo({
            specialOffer: currentArea?.special_offer?.text || "",
            phoneNumber: currentArea?.phone_number || "",
            schedule: currentArea?.schedule_weekday || "",
            serviceFee:
              currentCity?.service_fee || currentArea?.service_fee || "",
            membershipServiceFee:
              currentCity?.membership_service_fee ||
              currentArea?.membership_service_fee ||
              "",
          });
        }
      };
      updateStateValues();
    }
  }, [stateData, location]);

  const updateLocation = ({ city, state }) => {
    setLocation({ city, state });
  };

  const isLocationInDatabase = useMemo(
    () =>
      stateData?.some(
        (state) =>
          state.name === location.state &&
          state.areas.some((area) =>
            area.cities.some((city) => city.name === location.city)
          )
      ),
    [stateData, location]
  );
  const locationValue = isLocationInDatabase
    ? location
    : { city: "Choose your city", state: " " };

  const defaultPhoneNumber = useMemo(
    () => stateData?.[0]?.areas[0]?.phone_number || "",
    [stateData]
  );

  return (
    <LocationContext.Provider
      value={{
        location: locationValue,
        updateLocation,
        ...stateInfo,
        defaultPhoneNumber,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
