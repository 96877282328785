import React, { useState, useRef, useEffect, useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PatternFormat } from 'react-number-format';

import ReCAPTCHA from 'react-google-recaptcha';

import useOmgService, {data} from '../../services/OmgService';
import { LocationContext } from "../Header/LocationInputs/LocationContext";

import './callbackForm.scss';
import phone__mobile from "../../assets/images/header/phone_icon.svg";

const CallbackForm = ({ isOpen, onClose }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const wrapperRef = useRef(null);
  const recaptchaRef = useRef(null);

  const {baseUrl} = useOmgService('');

  const handleRecaptchaExpired = () => {
    const retryInterval = setInterval(() => {
      if (window.grecaptcha) {
        clearInterval(retryInterval);}
    }, 1000);
  };
  function onChange() {
    if (recaptchaRef && recaptchaRef.current && recaptchaRef.current.reset) {
         recaptchaRef.current.reset()
     }
   }



  const handlePhoneClick = (phoneNumber) => {
    if (phoneNumber) {
      window.location.href = `tel:${phoneNumber.replace(/\D/g, "")}`;
    }
  };

  const { phoneNumber } = useContext(LocationContext);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const formik = useFormik({
    initialValues: {
        tel: '',
        message: '',
        zip_code: ''
    },
    validationSchema: Yup.object({
        tel: Yup.string()
                .matches(/ *\+?(?:[-)(\s]?\d+[-)(\s]?)+$/, 'Invalid phone number')
                .required('The phone number field is required'),
        zip_code: Yup.string()
                    .matches(/^(0\d{4}|\d{5})$/, 'Required field')
                    .min(5, 'Invalid zip code/ Min 5 digits')
                    .max(5, 'Invalid zip code/ Max 5 digits')
                    .required('The zip-code field is required'),
        message: Yup.string()
                    .min(2, 'Invalid message/ Min 2 characters')
                    .required('The message field is required')

    }),
    onSubmit:
     async (values, { resetForm }) => {
      try {
        setIsSubmitting(true);
        const currentPage = window.location.href;
        const pathPage = `${values.message}\nCurrent Page: ${currentPage}`;
          const token = await recaptchaRef.current.executeAsync();
          const response = await fetch(`${baseUrl}/callback`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              ...values,
              captchaValue: token,
              message: pathPage,
            }),
          });
        
           if (!response.ok) {
            console.error('Form submission failed');
            setSuccess(false);
            setError(true);
            setIsSubmitting(false);
          } else {
            console.log('Form data submitted');
            resetForm();
            setSuccess(true);
            setError(false);
            setIsSubmitting(false);
          } 
        }
       catch (error) {
        console.error('Error occurred while submitting the form data:', error);
        setSuccess(false);
        setError(true);
        } 
     }
    });

  

  return (

		<div  className="form-callback__modal-overlay">
      <div className="container">	
      {!success && !error && (<div ref={wrapperRef} className="form-callback__wrapper form-callback__wrapper_main ">
          
          <form
            action="POST" 
            className="form-callback" 
            onSubmit={(event) => {
              event.preventDefault();
              formik.handleSubmit(event);
              }}>
            <button className="form-callback__btn-close" onClick={onClose}>&#10006;</button>
            <ReCAPTCHA
              badge='bottomleft'
              onChange={onChange}
              onExpired={handleRecaptchaExpired}
              ref={recaptchaRef}
              sitekey="6Lf3x9EmAAAAAK3Wg6LPjWefKx1Isr_IBLHVZQVA"
              size="invisible"
            />
            <h6 className="form-callback__subtitle">Contact information</h6>
            <div className="form-callback__input-wrapper">
            <label className="visually-hidden" htmlFor="tel">phone number</label>
            <PatternFormat 
              format="+1(###) ### ####" 
              allowEmptyFormatting={false}
              mask="_"
              className={formik.errors.tel && formik.touched.tel ? 'invalid-field' : ''}
              id="callback_tel"
              name="tel"
              value={formik.values.tel}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder='Phone*'
              />            
            {formik.errors.tel && formik.touched.tel ? <span className='error-form_callback'>{formik.errors.tel}</span> : null}
            </div>

            <div className="form-callback__input-wrapper">
              <label className="visually-hidden" htmlFor="zip-code">zip-code</label>
            <input
                className={formik.errors.zip_code && formik.touched.zip_code ? 'invalid-field' : ''}
                id ="callback_zip-code"
                name="zip_code"
                type="text"
                maxLength="5"
                value={formik.values.zip_code === 0 ? '' : formik.values.zip_code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder='ZIP Code*' />
            {formik.errors.zip_code && formik.touched.zip_code ? <span className='error-form_callback'>{formik.errors.zip_code}</span> : null}
            </div>
            
            <div className="form-callback__input-wrapper">
            <label className="visually-hidden" htmlFor="message">message</label>
            <textarea
                className={formik.errors.message && formik.touched.message ? 'invalid-field' : ''}
                id="callback_message"
                name="message"
                placeholder="Additional information*"
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}></textarea>
            {formik.errors.message && formik.touched.message ? <span className='error-form_callback error-form_callback_message'>{formik.errors.message}</span> : null}
            </div>

            <div className="form-callback__privacy-policy__wrapper">
              <p className='form-callback__privacy-policy'>By clicking BOOK A SERVICE below, you consent to our <a className='form__privacy-policy_link' target="_blank" rel="noopener noreferrer" href={data.policy}> Privacy Policy</a>.</p>
              <p className='form-callback__privacy-policy form-callback__privacy-policy_google'>This site is protected by <b>reCAPTCHA </b> and <b>the Google </b><a className='form__privacy-policy_link' href="https://policies.google.com/privacy"> Privacy Policy</a> and <a className='form__privacy-policy_link' href="https://policies.google.com/terms"> Terms of Service </a> apply.</p>
            </div>
            

            <button
              type="submit"
              className={!formik.isValid || isSubmitting ? 'form-callback__btn form-callback__btn_disable' : 'form-callback__btn'}
              disabled={!formik.isValid || isSubmitting}
            >
              {isSubmitting ? 'Sending...' : 'Send'}
            </button>


          </form>


        </div>)}
        {error && (
            <div id='infomodal' ref={wrapperRef} className="form-callback__wrapper form-callback__wrapper_infomodal">
              <button className="form-callback__btn-close" onClick={onClose}>&#10006;</button>
              <h2 className='form__modal-title' style={{ textAlign: 'center' }}>Error!</h2>
              <p className='form__modal-subtitle'>Failed to submit the form.</p>
              <p className='form__modal-phone'>Please contact us at </p>
              <div className="form-callback__wrapper_phone">
                <img src={phone__mobile} alt="phone icon" />
                <div className="phone__number" onClick={() => handlePhoneClick(phoneNumber)}>
                {phoneNumber}
                </div>
              </div>
              
            </div>)}
          

          {success && (
            <div id='infomodal' ref={wrapperRef} className="form-callback__wrapper form-callback__wrapper_infomodal">
              <button className="form-callback__btn-close" onClick={onClose}>&#10006;</button>
              <h2 className='form__modal-title'>THANK YOU!</h2>
              <p className='form__modal-subtitle' style={{ marginBottom: '0' }}>We'll contact you as quickly as possible!</p>
            </div>)}
    </div>
	</div>
        
  )
}

export default CallbackForm;