export const data = {
  policy: `${window.location.origin}/fstatic/policy.html`,
  cookies: `${window.location.origin}/fstatic/cookie.html`,
};

const useOmgService = () => {
  
  const baseUrl = 'https://omg-repair.com/api/v1';

  const request = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Request failed with status: " + response.status);
      }
      return response.json();
    } catch (error) {
      console.error("Request failed:", error);
      throw error;
    }
  };

  const getRequestUrl = (path) => {
    return `${baseUrl}/${path}`;
  };

  const getReviews = async() => {
    const url = getRequestUrl('reviews');
    const res = await request(url);
    return res;
  };

  const getVideoAboutUs = async() => {
    const url = getRequestUrl('about');
    const res = await request(url);
    return res;
  };

  const getVideos = async () => {
    const url = getRequestUrl('blog/videos');
    const res = await request(url);
    return res;
  };

  const getAllBlogPosts = async() => {
    const url = getRequestUrl('blog/posts');
    const res = await request(url);
    return res;
  };
  
  const getSingleBlogPost = async(id) => {
    const url = getRequestUrl(`blog/posts/${id}`);
    const res = await request(url);
    return res;
  };

  const getBlogCategories = async() => {
    const url = getRequestUrl('blog/categories');
    const res = await request(url);
    return _transformCategories(res);
  };

  const getAllQuestions = async() => {
    const url = getRequestUrl('questions');
    const res = await request(url);
    return res;
  };

  const getСity = async() => {
    const url = getRequestUrl('city');
    const res = await request(url);
    return res;
  };

  const getPrice = async () => {
    const url = getRequestUrl('price');
    const res = await request(url);
    return res;
  };

  const getMembership = async () => {
    const url = getRequestUrl('membership');
    const res = await request(url);
    return res;
  };
  const getBrandsList = async () => {
    const url = getRequestUrl('brandlist');
    const res = await request(url);
    return res;
  };

  const getContact = async () => {
    const url = getRequestUrl('contact');
    const res = await request(url);
    return res;
  };

  const getReasons = async() => {
    const url = getRequestUrl('reasons');
    const res = await request(url);
    return res;
  };
  
  const _transformCategories = (categories) => {
    return categories && categories.map(item => item.title);
  };
  return {
    getVideos,
    getAllBlogPosts,
    getAllQuestions,
    getСity,
    getContact,
    getPrice,
    getMembership,
    getBrandsList,
    getReviews,
    getVideoAboutUs,
    getReasons,
    getBlogCategories,
    baseUrl,
    getSingleBlogPost
  };
};

export default useOmgService;
