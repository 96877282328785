import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ScrollToTop from "../ScrollToTop/ScrollToTop";
// import MainPage from '../pages/MainPage';
// import Page404 from '../pages/404';
import CookieConsent from "../CookieConsent/CookieConsent";
import IconCallbackForm from "../CallbackForm/IconCallbackForm/IconCallbackForm";
// import FridgeRef from '../pages/Appliances/FridgeRef';
// import Washer from '../pages/Appliances/Washer';
// import Dryer from '../pages/Appliances/Dryer';
// import Dishwasher from '../pages/Appliances/Dishwasher';
// import Microwave from '../pages/Appliances/Microwave';
// import StoveOvenRangeCook from '../pages/Appliances/StoveOvenRangeCook';
// import Blog from '../pages/Blog/Blog';
// import SingleBlogPage from '../pages/SingleBlogPage/SingleBlogPage';
// import Pricing from '../pages/Pricing/Pricing';
// import AboutUs from '../pages/AboutUs/AboutUs';
// import Contacts from '../pages/Contacts/Contacts';
import { OmgServiceProvider } from "../../services/OmgServiceContext";
import { LocationProvider } from "../Header/LocationInputs/LocationContext";

import "../../style/style.scss";

const Page404 = lazy(() => import("../pages/404"));
const MainPage = lazy(() => import("../pages/MainPage"));
// const CookieConsent = lazy(() => import('../CookieConsent/CookieConsent'));
const FridgeRef = lazy(() => import("../pages/Appliances/FridgeRef"));
const Washer = lazy(() => import("../pages/Appliances/Washer"));
const Dishwasher = lazy(() => import("../pages/Appliances/Dishwasher"));
const Microwave = lazy(() => import("../pages/Appliances/Microwave"));
const Dryer = lazy(() => import("../pages/Appliances/Dryer"));
const StoveOvenRangeCook = lazy(() =>
  import("../pages/Appliances/StoveOvenRangeCook")
);
const Fireplace = lazy(() => import("../pages/Appliances/Fireplace"));
const Blog = lazy(() => import("../pages/Blog/Blog"));
const SingleBlogPage = lazy(() =>
  import("../pages/SingleBlogPage/SingleBlogPage")
);
const Pricing = lazy(() => import("../pages/Pricing/Pricing"));
const AboutUs = lazy(() => import("../pages/AboutUs/AboutUs"));
const Contacts = lazy(() => import("../pages/Contacts/Contacts"));

const App = () => {
  return (
    <OmgServiceProvider>
      <LocationProvider>
        <Router>
          <ScrollToTop />
          <main>
            <Suspense
              fallback={
                <div className="loader__container">
                  <div className="loader"></div>
                </div>
              }
            >
              <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="*" element={<Page404 />} />
                <Route
                  path="/fridge-refrigerator-repair"
                  element={<FridgeRef />}
                />
                <Route path="/dryer-repair" element={<Dryer />} />
                <Route path="/washer-repair" element={<Washer />} />
                <Route path="/dishwasher-repair" element={<Dishwasher />} />
                <Route path="/microwave-repair" element={<Microwave />} />
                <Route
                  path="/stove-oven-range-cooktop-repair"
                  element={<StoveOvenRangeCook />}
                />
                <Route path="/fireplace-repair" element={<Fireplace />} />

                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:id" element={<SingleBlogPage />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/contacts" element={<Contacts />} />
              </Routes>
            </Suspense>
          </main>
          <CookieConsent />
          <IconCallbackForm />
        </Router>
      </LocationProvider>
    </OmgServiceProvider>
  );
};

export default App;
