import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import {data} from '../../services/OmgService';

import './CookieConsent.scss';

const CookieConsent = ({ onConsent = () => {} }) => {
  const [consented, setConsented] = useState(false);
  const [hasCheckedConsent, setHasCheckedConsent] = useState(false); 
  useEffect(() => {
    const hasConsented = localStorage.getItem('cookieConsent');
    if (hasConsented) {
      setConsented(true);
    }
    setHasCheckedConsent(true); 
  }, []);

  const handleConsent = () => {
    setConsented(true);
    localStorage.setItem('cookieConsent', true);
    onConsent();
  }; 

  if (consented || !hasCheckedConsent) {
    return null;
  }

  return (
    <>
      {createPortal(
        <div className="container cookie-consent_wrapper">
          <div className="cookie-consent">
          <p className="cookie-consent__text">We use cookies to enhance your user experience on our website. By continuing to browse this site, you consent to the use of our <a className='cookie-consent__text_link' href={data.cookies} target="_blank" rel="noopener noreferrer"> Cookie Policy</a>.</p>
          <button className="cookie-consent__btn btn" onClick={handleConsent}>Ok</button>
        </div>
        </div>,
      document.body
      )}
    </>
  )
};

export default CookieConsent;