import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import CallbackForm from '../CallbackForm';
import letterButton from './../../../assets/icons/callbackForm/letterButton.svg';

import './iconCallbackForm.scss';

const IconCallbackForm = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setModalOpen(false);
    document.body.style.overflow = 'auto';
  };

  return (
    <>
    {createPortal(
      <div className='container'>
          <div className="icon-letter">
              <button className='icon-letter__btn' onClick={openModal}>
                  <img src={letterButton} alt="button open callback form" />
              </button>
              {isModalOpen && (
                  <CallbackForm isOpen={isModalOpen} onClose={closeModal} />
              )}
          </div>
      </div>
      ,
      document.body
    )}
    </>
  );
};

export default IconCallbackForm;
