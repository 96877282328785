import React, { createContext, useState, useEffect } from "react";
import useOmgService from "./OmgService";

const OmgServiceContext = createContext();

const OmgServiceProvider = ({ children }) => {
  const omgService = useOmgService();
  const [reasonsData, setReasonsData] = useState(null);
  const [reasError, setReasError] = useState(false);
  const [cityData, setCityData] = useState(null);
  const [cityError, setCityError] = useState(false);
  const [questionsData, setQuestionsData] = useState(null);
  const [questionsError, setQuestionsError] = useState(false);
  const [blogsData, setBlogsData] = useState(null);
  const [blogsError, setBlogsError] = useState(false);
  const [contactsData, setContactsData] = useState(null);
  const [contactsError, setContactsError] = useState(false);
  const [videosData, setVideosData] = useState(null);
  const [videosError, setVideosError] = useState(false);
  const [reviews, setReviews] = useState(false);
  const [reviewsError, setReviewsError] = useState(false);
  const [videoAboutUs, setVideoAboutUS] = useState("");
  const [videoAboutUsError, setVideoAboutUSError] = useState("");
  const [brands, setBrands] = useState([]);
  const [brandsError, setBrandsError] = useState(false);
  const [pricing, setPricing] = useState(null);
  const [pricingError, setPricingError] = useState(false);
  const [membership, setMembership] = useState();
  const [membershipError, setMembershipError] = useState(false);

  useEffect(() => {
    const fetchReasons = async () => {
      try {
        const fetchedReasons = await omgService.getReasons();
        setReasonsData(fetchedReasons);
      } catch (error) {
        setReasError(true);
      }
    };
    const fetchCity = async () => {
      try {
        const fetchedCity = await omgService.getСity();
        setCityData(fetchedCity);
      } catch (error) {
        setCityError(true);
      }
    };
    const fetchQuestions = async () => {
      try {
        const fetchedQuestions = await omgService.getAllQuestions();
        setQuestionsData(fetchedQuestions);
      } catch (error) {
        setQuestionsError(true);
      }
    };
    const fetchBlogs = async () => {
      try {
        const fetchedQuestions = await omgService.getAllBlogPosts();
        setBlogsData(fetchedQuestions);
      } catch (error) {
        setBlogsError(true);
      }
    };

    const fetchContacts = async () => {
      try {
        const fetchedContacts = await omgService.getContact();
        setContactsData(fetchedContacts);
      } catch (error) {
        setContactsError(true);
      }
    };

    const fetchVideos = async () => {
      try {
        const fetchedVideos = await omgService.getVideos();
        setVideosData(fetchedVideos);
      } catch (error) {
        setVideosError(true);
      }
    };

    const fetchReviews = async () => {
      try {
        const reviews = await omgService.getReviews();
        setReviews(reviews);
      } catch (error) {
        setReviewsError(true);
      }
    };

    const fetchVideoAboutUs = async () => {
      try {
        const videoAboutUsLink = await omgService.getVideoAboutUs();
        setVideoAboutUS(videoAboutUsLink);
      } catch (error) {
        setVideoAboutUSError(true);
      }
    };
    const fetchMembership = async () => {
      try {
        const fetchedMembrship = await omgService.getMembership();
        setMembership(fetchedMembrship);
      } catch (error) {
        setMembershipError(true);
      }
    };
    const fetchPrice = async () => {
      try {
        const fetchedPrice = await omgService.getPrice();
        setPricing(fetchedPrice);
      } catch (error) {
        setPricingError(true);
      }
    };
    const fetchBrandsList = async () => {
      try {
        const fetchedBrands = await omgService.getBrandsList();
        setBrands(fetchedBrands);
      } catch (error) {
        setBrandsError(true);
      }
    };

    fetchReasons();
    fetchCity();
    fetchQuestions();
    fetchBlogs();
    fetchContacts();
    fetchVideos();
    fetchVideoAboutUs();
    fetchReviews();
    fetchMembership();
    fetchPrice();
    fetchBrandsList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!contactsData) {
    return null;
  }

  const contextValue = {
    getReasons: () => reasonsData,
    reasError: reasError,
    getCity: () => cityData,
    cityError: cityError,
    getQuestions: () => questionsData,
    questionsError: questionsError,
    getBlogs: () => blogsData,
    blogsError: blogsError,
    getContacts: () => contactsData,
    contactsError: contactsError,
    getVideos: () => videosData,
    videosError: videosError,
    getReviews: () => reviews,
    reviewsError: reviewsError,
    getVideoAboutUs: () => videoAboutUs,
    videoAboutUsError: videoAboutUsError,
    getMembership: () => membership,
    membershipError: membershipError,
    getPrice: () => pricing,
    pricingError: pricingError,
    getBrandsList: () => brands,
    brandsError: brandsError,
  };

  return (
    <OmgServiceContext.Provider value={contextValue}>
      {children}
    </OmgServiceContext.Provider>
  );
};

export { OmgServiceContext, OmgServiceProvider };
